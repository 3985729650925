<template>
  <main id="content" class="apply">
    <router-view
      ref="childView"
      :accApplyPgmMngList="accApplyPgmMngList"
      :isApplyCompleted="isApplyCompleted"
    />
    <!-- 공모 지원하기 Floating Button -->
    <!-- <div
      v-if="isApplicable"
      ref="accApplyFloatingButton"
      class="floating-wrap apply"
      :class="{ on: doShowAccApplyFloatingButton, scrolling: isScrolling }"
    >
        <div v-if="mobiledHackerton"
           @click="noDisplay"
          tag="button"
          class="btn-floating">
          <span class="text">{{$t('content.programs.Application.btn.txt01')}}</span>
        </div>
        <div v-else-if="(!isApplyCompleted && hackerton)" @click="applyTo">
          <button class="btn-floating">{{$t('content.programs.Application.btn.txt01')}}</button>
        </div>
        <router-link v-else-if="(!isApplyCompleted && accApplyPgmMngList[0].accKind === 'AC108')"
          :to="{ name: 'ProgramsApplyStep1',
          params: {
            accPgmMngNoString: ''+accApplyPgmMngList[0].accPgmMngNo
          } }"
          tag="button"
          class="btn-floating"
        ><span class="text">{{$t('content.programs.Application.btn.txt01')}}</span></router-link>
        <router-link v-else-if="!isApplyCompleted && accApplyPgmMngList[0].accKind !== 'AC108'"
         :to="{ name: 'ProgramsApplication' }"
          tag="button"
          class="btn-floating"
        ><span class="text">{{$t('content.programs.Application.btn.txt01')}}</span></router-link>
        <router-link v-else
          :to="{ name: 'ProgramsMyApplyList',
          params: {
            accPgmMngNoString: ''+accApplyPgmMngList[0].accPgmMngNo
          } }"
          tag="button"
          class="btn-floating"
        ><span class="text">{{$t('content.programs.Application.btn.txt01')}}</span></router-link>
      <svg viewBox="0 0 150 150" class="rotate-circle">
        <path
          id="curve"
          fill="transparent"
          d="M 75 75 m -50, 0 a 50, 50 0 1, 1 100, 0 a 50, 50 0 1, 1 -100, 0"
        />
        <text class="caption">
          <textPath xlink:href="#curve">{{$t('content.programs.Application.btn.txt02')}}</textPath>
        </text>
      </svg>
    </div> -->
    <!-- // 공모 지원하기 Floating Button -->
  </main>
</template>

<script>
import { mixin as mixinHelperUtils } from '@/common/helperUtils';
import { gsap } from '@/common/motions';
import MobileDetect from 'mobile-detect';
import debounce from 'lodash/debounce';
import ProgramsApplicationService from './application/service/ProgramsApplicationService';

let lastKnownWindowScrollY = 0;
let ticking = false;

/**
 * Programs
 */
export default {
  name: 'Programs',
  mixins: [
    mixinHelperUtils,
  ],

  data() {
    return {
      /**
       * Acc지원프로그램 관리 목록
       * @type {import('./application/dto/AccApplyPgmMngDetailDto').default[]}
       */
      accApplyPgmMngList: undefined,

      /**
       * 공모 지원하기 Floating Button 표시 여부
       * @type {boolean}
       */
      doShowAccApplyFloatingButton: false,

      /**
       * 지원완료 여부
       * @type {boolean}
       */
      isApplyCompleted: false,
      mobiledHackerton: false,
      hackerton: false,
      debounce: {
        moStopScroll: debounce(this.moStopScroll, 50),
      },
      isScrolling: false,
      // isPC: true,
    };
  },

  computed: {
    /**
     * 공모 지원하기 가능 여부
     * @type {boolean}
     */
    isApplicable() {
      const { path } = this.$route;
      // 지원가능한 공모 목록이 있는 경우에만 공모 지원하기 가능
      // 그리고 공모 지원하기 화면에서는 제외
      // 조건 추가 acc가 AC108이면서 url apply 이거나 AC108아니며 url programs 일 경우 가능
      if (this.accApplyPgmMngList) {
        if (this.accApplyPgmMngList.length > 0) {
          if (((/\/programs\/programs/.test(path)) && this.accApplyPgmMngList[0].accName.indexOf('해커톤') !== -1)) {
            return false;
          }
        }
      }

      return this.accApplyPgmMngList
        && this.accApplyPgmMngList.length > 0
        && (
          ((path.match(/\/apply/) || path.match(/\/applyweb/)) && this.accApplyPgmMngList[0].accKind === 'AC108')
          || ((path.match(/\/programs/) && this.accApplyPgmMngList[0].accKind !== 'AC108')
          )
        )
        && !(/\/programs\/application/.test(path));
    },

    /**
     * K - STARTUP MOBILE 구분
     * @type {boolean}
     */
  },

  watch: {
    $route() {
      // 공모 지원하기 버튼 숨기기
      this.doShowAccApplyFloatingButton = false;

      if (this.isApplicable) {
        this.showAccApplyFloatingButton();
      }
    },
    /**
     * 공모 지원하기 가능 여부 변경시
     */
    isApplicable(newVal, oldVal) {
      if (newVal && !oldVal) { // 가능으로 변경시
        this.showAccApplyFloatingButton();
      }
    },
  },

  async created() {
    this.programsApplicationService = new ProgramsApplicationService(this.portalApiClient);
    const md = new MobileDetect(window.navigator.userAgent);
    if (window.location.pathname === '/programs/hackathon') {
      // this.isPC = true;
      this.hackerton = true;
      this.accApplyPgmMngList = await this.programsApplicationService.getAccApplySpecialPgmMng();
      if (md.mobile() === null) {
        this.mobiledHackerton = false;
      } else {
        this.mobiledHackerton = true;
      }
    } else {
      // this.isPC = md.mobile() === null;
      this.accApplyPgmMngList = await this.programsApplicationService.getAccApplyPgmMngList();
    }
    // 로그인 상태이면 지원완료 여부 조회
    const { userInfo } = this.$store.state;
    // if (userInfo && this.accApplyPgmMngList.length > 0) {
    //   await this.programsApplicationService.getTempAccApply(this.accApplyPgmMngList[0].accPgmMngNo)
    //     .then(({ status }) => {
    //       this.isApplyCompleted = (status === 737); // 737: 이미 지원한 내역이 있습니다.
    //     });
    // }

    // scroll event : https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event
    if (md.mobile()) {
      window.addEventListener('scroll', this.moScroll);
    } else {
      window.addEventListener('scroll', this.onWindowScroll);
    }
  },
  destroyed() {
    // scroll event : https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event
    window.removeEventListener('scroll', this.onWindowScroll);
    window.removeEventListener('scroll', this.moScroll);
  },

  methods: {
    moStopScroll() {
      setTimeout(() => {
        this.isScrolling = false;
      }, 500);
    },
    moScroll() {
      this.isScrolling = true;
      this.debounce.moStopScroll();
    },
    noDisplay() {
      this.showAlert('공모 지원은 PC 환경에서만 지원 가능합니다. PC 화면에서 진행해 주시기 바랍니다.');
    },
    /**
     * 공모 지원하기 버튼 표시하기
     */
    showAccApplyFloatingButton() {
      this.$nextTick(() => {
        const el = this.$refs.accApplyFloatingButton;

        // 공모 지원하기 버튼 커버 이후 나타나기
        const { cover } = this.$refs.childView.$refs;
        if (cover) {
          const offsetY = 168;

          gsap.fromTo(
            el,
            {
              autoAlpha: 0,
              y: -30,
            },
            {
              autoAlpha: 1,
              y: () => window.scrollY || window.pageYOffset || 0, // ie11
              scrollTrigger: {
                // markers: true,
                trigger: '.title-section',
                endTrigger: 'footer',
                start: `top 1%+=${offsetY}`,
                once: true,
                onEnter: () => {
                  this.doShowAccApplyFloatingButton = true;
                },
              },
              duration: 1.4,
              ease: 'Back.easeOut',
            },
          );
        } else {
          this.doShowAccApplyFloatingButton = true;

          const md = new MobileDetect(window.navigator.userAgent);
          const pY = md.mobile() ? 0 : window.scrollY || window.pageYOffset || 0;
          gsap.fromTo(
            el,
            {
              autoAlpha: 0,
              y: -30,
            },
            {
              autoAlpha: 1,
              y: pY,
              duration: 1.4,
              delay: 2,
              ease: 'Back.easeOut',
            },
          );
        }
      });
    },

    /**
     * window 스크롤 이벤트 처리
     * @see window.scrollY : https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollY
     *      * ie11 : https://github.com/zwug/react-full-page/pull/31/commits/3383968609c9437cf1d11d9ef24aaa51eb2fad2b
     * @see ease : https://greensock.com/docs/v3/Eases
     */
    onWindowScroll() {
      lastKnownWindowScrollY = window.scrollY || window.pageYOffset || 0; // ie11
      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (this.isApplicable && this.doShowAccApplyFloatingButton) {
            // 공모 지원하기 버튼 커버로는 이동하지 않도록
            const { cover } = this.$refs.childView.$refs;
            if (cover && lastKnownWindowScrollY <= (cover.offsetHeight - 50)) {
              ticking = false;
              return;
            }

            const el = this.$refs.accApplyFloatingButton;
            gsap.to(
              el,
              {
                y: lastKnownWindowScrollY,
                duration: 0.5,
                ease: 'power1.inOut',
              },
            );
          }

          ticking = false;
        });

        ticking = true;
      }
    },
    applyTo() {
      if (window.gtag) {
        const userInfo = this.$store.getters.getUserInfo();
        if (userInfo) {
          window.gtag('event', 'First_Apply_login_221014_btn1', {
            event_category: 'apply',
            event_label: 'First_Apply_login_221014',
          });
        } else {
          window.gtag('event', 'First_Apply_Nologin_221014_btn1', {
            event_category: 'apply',
            event_label: 'First_Apply_Nologin_221014',
          });
        }
      }
      this.$router.push({
        name: 'ProgramsApplyStep1',
        params: {
          accPgmMngNoString: `${this.accApplyPgmMngList[0].accPgmMngNo}`,
        },
      });
    },
  },
};
</script>
